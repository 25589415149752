import * as React from "react";
import { useEffect } from "react";

const TranslationStyleGuidesPage = () => {
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        window.location = 'https://blog.andovar.com/translators-style-guide';
      }
    },[])
  return <></>;
};
export default TranslationStyleGuidesPage;
